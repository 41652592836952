import React from "react";
import { Link } from "react-router-dom";
import "../Stylesheet/component.css";

const Footer = () => {
  return (
    <footer className="footer">
      <Link to="/survivor" className="button_link">
        Join as a Survivor
        <span className="hidden lg:inline"> or Caregiver Mentor</span>
      </Link>
      <div className="mt-6">
        <ul className="flex gap-x-5 justify-center text-white">
          <li>
            <a
              href="http://www.instagram.com/officialgleemora/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1702894481/Gleemora/Instagram_icon.png_mgzalk.webp"
                alt="insta"
                className="w-10 h-10"
              />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/GleemoraHQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1702894482/Gleemora/xicon_psx2jn.png"
                alt="x-icon"
                className="w-10 h-10"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/gleemora/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1702894480/Gleemora/linkdln_mrbrgj.png"
                alt="linkdln"
                className="w-11 h-11 rounded-lg"
              />
            </a>
          </li>
        </ul>
      </div>
      <div className="main_foot">
        <div className="footer_link grid lg:flex gap-y-5 gap-x-12">
          <Link to="faq">FAQs</Link>
          <Link to="about">About Us</Link>
          <Link to="contact">Contact</Link>
          <Link to="policy">Privacy Policy</Link>
          <Link to="Story">Founder's Story</Link>
          <Link to="terms">Terms and Conditons</Link>
          <Link to="guidelines">Guidelines</Link>
        </div>
      </div>
      <p className="text-center text-[#9f9f9f] mt-5">
        2023 © Gleemora. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
