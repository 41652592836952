import React from "react";
import { Link } from "react-router-dom";

const StorySection = () => {
  return (
    <>
      <div className="py-12">
        <div className="boxing">
          <div className="lg:flex flex-row-reverse justify-between items-center">
            <div className="lg:w-3/12">
              <img
                src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1701951271/Gleemora/Blob_5_1_j8uwzz.png"
                alt="founder_img"
                className="w-full"
              />
            </div>
            <div className="lg:w-8/12 mt-12 lg:mt-0">
              <p className="text-2xl lg:text-4xl syne font-semibold">
                Founder's Story
              </p>
              <div className="mt-12">
                <p className="text-lg lg:text-[19px] leading-9 font-medium">
                  Balancing my struggle with uterine fibroids with a demanding
                  career meant wearing a mask of wellness. My commitment to my
                  profession never wavered, yet it was a journey marked by
                  solitude and resilience. This experience shed light on a
                  broader, often unspoken reality: the silence surrounding
                  health issues in our professional lives. It's a stark reminder
                  of the need for more open and supportive dialogues about
                  health in the workplace.
                </p>
                <div className="mt-12 lg:flex items-center gap-x-4">
                  <Link
                    to="/story"
                    className="bg-grad text-white font-semibold rounded-[25px] lg:rounded-[40px] px-6 lg:px-12 lg:py-5 py-3"
                  >
                    Beyond the Medical
                  </Link>
                  <span className="text-lg font-medium block mt-5 lg:mt-0">My Personal Quest for Emotional Support</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorySection;
