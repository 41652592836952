import React from "react";
import "../Stylesheet/home.css";
import Main from "./Main";
import { HashLink } from "react-router-hash-link";

const Home = () => {
  return (
    <div className="main_page">
      <div className="home">
        <div className="home_text">
          <h2 className="lg:w-9/12 2xl:w-8/12 mulish">
            Navigate your <span>health challenges</span>{" "}
            hand-in-hand with <span>Survivors</span> - from influential
            achievers to career professionals who&apos;ve walked your path.
          </h2>
          <HashLink smooth to="/#join" className="button_link mt-12 lg:mt-9 2xl:mt-12">
            Get early access
          </HashLink>
        </div>
      </div>
      <Main />
    </div>
  );
};

export default Home;
